<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import PrepModuleService from "@/services/PrepModuleService";

	// Other
	import qs from "qs";

	export default {
		props: {
			value: {
				default: null
			},
			type: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = setSelected(newValue, this.options)
			}
		},
		data() {
			return {
				selected: null,
				active: null,
				options: []
			}
		},
		created() {
			this.getOptions()
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				let filter = {};
				if (this.type) {
					filter.type = this.type;
				}

				const config = {
					params: {
						filter: filter,
						sort: 'module',
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				this.options = []
				PrepModuleService.getActive(config)
				                 .then(response => {
					                 const data = response.data.data;

					                 let active = null;
					                 data.forEach((item) => {
						                 this.options.push({
							                 value: item.module,
							                 text: item.module_name,
						                 });
						                 if (item.is_active) {
							                 this.active = item.module;
						                 }
					                 })
				                 })
				                 .then(() => {
					                 if (this.value) {
						                 this.selected = setSelected(this.value, this.options)
					                 }
					                 else if (this.active) {
						                 this.selected = setSelected(this.active, this.options)
                                         this.$emit('activeModule', this.active)
					                 }
				                 })
			}
		}
	}
</script>
